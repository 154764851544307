import { Page } from '@/components'
import type { ProjectMembershipRoleType } from 'common'
import { ProjectMembershipRole } from 'common'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, CardWithContent, Input, LoadingButton, type RFC, useToast } from 'ui'
import { AvatarWithFallback } from 'ui/src'
import { useProject, useProjectId } from '../../contexts'
import { trpc } from '../../lib'

type TeamMember = {
  id: string
  email: string
  avatarUrl: string
  role: ProjectMembershipRoleType
  status: 'invited' | 'accepted'
  isSelf: boolean
}

type TeamMemberRowProps = {
  member: TeamMember
  removeTeamMember: (id: string) => void
}

const TeamMemberRow: RFC<TeamMemberRowProps> = ({ member, removeTeamMember }) => {
  return (
    <tr className="border-t">
      <td className="px-2 py-4">
        <AvatarWithFallback imageSrc={member.avatarUrl} email={member.email} fallback={member.email} />
      </td>
      <td className="px-2 py-4">{member.email}</td>
      <td className="px-2 py-4">{member.role}</td>
      <td className="px-2 py-4">{member.status}</td>
      <td className="px-2 py-4">
        <Button variant="ghost" className="border" onClick={() => removeTeamMember(member.id)} disabled={member.isSelf}>
          {member.isSelf ? 'You' : 'Remove'}
        </Button>
      </td>
    </tr>
  )
}

const TeamPage = () => {
  const projectId = useProjectId()
  const activeProject = useProject()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { data: teamMembers } = trpc.dashboard.team.listMembers.useQuery(
    {
      projectId,
    },
    {
      placeholderData: [],
      enabled: !!projectId,
    },
  )

  const sortedTeamMembers = (teamMembers || []).slice().sort((a, b) => {
    if (a.role === ProjectMembershipRole.OWNER) return -1
    if (b.role === ProjectMembershipRole.OWNER) return 1
    return 0
  })

  const removeTeamMember = trpc.dashboard.team.removeMember.useMutation()

  const handleRemoveTeamMember = React.useCallback(
    (id: string) => {
      if (projectId) {
        removeTeamMember.mutateAsync({ projectMembership: { id }, projectId }).then(() => {
          success({
            title: 'Success',
            message: 'Team member has been removed',
          })
        })
      }
    },
    [projectId],
  )

  const createInvite = trpc.dashboard.team.inviteMember.useMutation()
  const { success, error: showError } = useToast()

  const handleCreateInvite = React.useCallback(
    async ({ email }: { email: string }) => {
      try {
        setIsSubmitting(true)
        if (projectId) {
          await createInvite.mutateAsync({ email, projectId })
          success({ title: 'User invited', message: 'User has been invited' })
        }
      } catch (_err) {
        showError({
          title: 'Error',
          message: 'An error occurred while sending the invite.',
        })
      }
      setIsSubmitting(false)
    },
    [projectId, createInvite, success, showError],
  )

  type ICreateInviteForm = {
    email: string
  }
  const form = useForm<ICreateInviteForm>({})

  const pageContents =
    !activeProject || activeProject.planType === 'free' ? (
      <div>Teams not currently available on the free plan</div>
    ) : !sortedTeamMembers ? null : (
      <>
        <CardWithContent title="Team Members" className={'grid grid-cols-6'}>
          <table className="w-full">
            {sortedTeamMembers.map((member) => (
              <TeamMemberRow key={member.id} member={member} removeTeamMember={handleRemoveTeamMember} />
            ))}
          </table>
        </CardWithContent>

        <form onSubmit={form.handleSubmit(handleCreateInvite)}>
          <CardWithContent title="Invite Team Member">
            <div className="flex gap-md w-full">
              <Input type="email" placeholder="Enter email" required className="w-72" {...form.register('email')} />
              <LoadingButton isLoading={isSubmitting}>Invite</LoadingButton>
            </div>
          </CardWithContent>
        </form>
      </>
    )
  return <Page title="Team">{pageContents}</Page>
}

export default TeamPage
