import { FormGroup, Page } from '@/components'
import React from 'react'
import { SelectInput } from 'ui'

const UsagePage = () => {
  return (
    <Page title="Usage">
      <div className="grid grid-cols-2 gap-8">
        <FormGroup className="col-span-1" label="Show">
          <SelectInput options={[]} />
        </FormGroup>
        <FormGroup className="col-span-1" label="Over">
          <SelectInput options={[]} />
        </FormGroup>
      </div>
      <div>Compute Unit Usage</div>
      <div>Endpoint Breakdown</div>
    </Page>
  )
}

export default UsagePage
