import { type Stripe, loadStripe } from '@stripe/stripe-js'
import { useEffect } from 'react'
import { trpc } from '../lib/trpc'

let stripePromise: Promise<Stripe | null> = Promise.resolve(null)

export function getStripe(): typeof stripePromise {
  const stripePublishableKeyQuery = trpc.dashboard.billing.getStripePublishableKey.useQuery()

  useEffect(() => {
    if (stripePublishableKeyQuery.data) {
      stripePromise = loadStripe(stripePublishableKeyQuery.data)
    }
  }, [stripePromise, stripePublishableKeyQuery.data])
  return stripePromise
}
