import { createWSClient, httpLink, loggerLink, splitLink, wsLink } from '@trpc/client'
import type { CreateTRPCProxyClient, TRPCClient, TRPCWebSocketClient, WebSocketClientOptions } from '@trpc/react-query'
import superjson from 'superjson'
import { type ChainpipeApiRouter, chainpipeApiRouter } from '../../chainpipe-api/src/trpc/_app'
import { trpc } from './lib/trpc'

const wsURL = `wss://${window.location.host}/trpc`

export { type ChainpipeApiRouter } from '../../chainpipe-api/src/trpc/_app'

// @ts-ignore
export const wsClient: TRPCWebSocketClient = createWSClient({
  url: wsURL,
  retryDelayMs(attemptIndex) {
    if (attemptIndex > 2) {
      return 5000
    }
    return 1000
  },
})

// @ts-ignore
export const trpcClient: TRPCClient = trpc.createClient({
  links: [
    loggerLink({
      enabled: (opts) =>
        process.env.NODE_ENV === 'development' || (opts.direction === 'down' && opts.result instanceof Error),
    }),

    splitLink({
      condition(op) {
        return op.type === 'subscription'
      },
      true: wsLink({ client: wsClient }),

      false: httpLink({
        url: '/trpc',
        headers(_ctx) {
          const headers: Record<string, any> = {}
          const _authToken: string | undefined = undefined
          return headers
        },
      }),
    }),
  ],

  transformer: superjson,
})

import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'

export const proxyClient: CreateTRPCProxyClient<ChainpipeApiRouter> = createTRPCProxyClient<ChainpipeApiRouter>({
  transformer: superjson,
  links: [
    splitLink({
      condition(op) {
        return op.type === 'subscription'
      },
      true: wsLink({ client: wsClient }),

      false: httpLink({
        url: '/trpc',
        headers(_ctx) {
          const headers: Record<string, any> = {}
          const _authToken: string | undefined = undefined
          return headers
        },
      }),
    }),
  ],
}) as ReturnType<typeof createTRPCProxyClient<ChainpipeApiRouter>>

export type InferLoaderData<T extends (...args: any) => any> = Awaited<ReturnType<T>>
