import type { NetworkID } from '../network-id'

/**
 * These networks will be enabled and usable for chainpipe.
 */
const enabledNetworks = [
  'ethereum-mainnet',
  'ethereum-sepolia',
  'optimism-mainnet',
  'base-mainnet',
  'base-goerli',
  'base-sepolia',
  'arbitrum-mainnet',
  'arbitrum-sepolia',
  'polygon-mainnet',
  'blast-mainnet',
  'blast-sepolia',
  'scroll-mainnet',
  'scroll-sepolia',
  'cyber-mainnet',
  'cyber-sepolia',
] as const satisfies Array<NetworkID>

export const chainpipeConfig = {
  enabledNetworks,
  projectCookieName: 'project-id',
} as const

import Cookies from 'universal-cookie'

export const getProjectIdFromCookie = (cookiesString: string) => {
  const cookies = new Cookies(cookiesString)
  const projectId = cookies.get(chainpipeConfig.projectCookieName)
  if (!projectId) {
    throw new Error('Project ID is not set')
  }
  return projectId as string
}
