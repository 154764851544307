import { ButtonLink, ContractWithAddressCard } from '@/components'
import { useProjectId } from '@/contexts'
import { trpc } from '@/lib'
import { createColumnHelper } from '@tanstack/react-table'
import type { inferProcedureOutput } from '@trpc/server'
import { Plus } from 'lucide-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BetterTable, BlockRange, Column, Row } from 'ui'
import type { ChainpipeApiRouter } from '../../../../../../../../chainpipe-api/src/trpc/_app'

type T = NonNullable<
  inferProcedureOutput<ChainpipeApiRouter['sources']['evmContractV1']['listImplementationAddresses']>[number]
>
const columnHelper = createColumnHelper<T>()

const columns = [
  columnHelper.display({
    header: 'Address',
    cell(props) {
      const ct = props.row.original
      return <ContractWithAddressCard name={ct.label || '(Unnamed)'} address={ct.address} />
    },
  }),
  // columnHelper.accessor('blockRange', {
  //   header: 'Block Range',
  //   cell(props) {
  //     const value = props.getValue()
  //     return <BlockRange start={value.from} end={value.to} />
  //   },
  // }),
]

export const RelatedContractsTab = ({
  contractId,
  ...props
}: {
  contractId: string
}) => {
  const projectId = useProjectId()
  const [_newAddress, _setNewAddress] = useState('')

  const { data: implementationAddresses } = trpc.sources.evmContractV1.listImplementationAddresses.useQuery({
    projectId,
    id: contractId,
  })

  const navigate = useNavigate()

  return (
    <div {...props} className="space-y-lg">
      <Row>
        <Column>
          <h2>Related Contracts</h2>
          {/* <p>Related contracts can be things like proxies</p> */}
        </Column>
        <ButtonLink to="?tab=add-related" leftIcon={<Plus className="h-4 w-4" />}>
          Add Related Contract
        </ButtonLink>
      </Row>
      <BetterTable
        hoverable
        onNavigate={navigate}
        // @ts-ignore
        data={implementationAddresses}
        columns={columns}
      />
    </div>
  )
}
