import { useProjectId } from '@/components'
import { Page } from '@/components/page'
import { namedURLs, trpc } from '@/lib'
import { Plus } from 'lucide-react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { BetterForm, LoadingButton, type RFC, betterToast } from 'ui'

const NewPostgresManagedDbScreen: RFC = (props) => {
  const form = useForm({
    defaultValues: {
      label: '',
    },
  })
  const navigate = useNavigate()
  const createDestination = trpc.destinations.managedPostgres.create.useMutation()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const projectId = useProjectId()
  const onSubmit = React.useCallback(
    async (values: { label: string }) => {
      try {
        setIsSubmitting(true)
        const data = await createDestination.mutateAsync({
          projectId,
          label: values.label,
        })
        if (data?.id) {
          betterToast.success('Destination created.')
          navigate(
            namedURLs.destinations.show({
              id: data.id,
            }),
          )
        }
      } catch (_error) {
        betterToast.error('Destination could not be created.')
        setIsSubmitting(false)
      }
    },
    [setIsSubmitting, createDestination, projectId, navigate],
  )

  return (
    <Page {...props} narrow title="New Postgres Managed DB" description="Access a managed DB.">
      <BetterForm.Provider form={form} onSubmit={onSubmit}>
        <BetterForm.Field autoFocus name="label" placeholder="Production DB" label="A helpful name for this object" />
        <LoadingButton variant="blue" leftIcon={<Plus className="w-4 h-4" />} isLoading={isSubmitting}>
          Create Managed Postgres
        </LoadingButton>
      </BetterForm.Provider>
    </Page>
  )
}

export default NewPostgresManagedDbScreen
