import 'prismjs/themes/prism.css' //Example style, you can use another
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-yaml'
import { type RFC, clsx } from 'common-web'
import Editor from 'react-simple-code-editor'

export const CodeEditor: RFC<React.HTMLProps<HTMLTextAreaElement>> = ({ className = undefined, ...props }) => {
  return (
    <Editor
      highlight={(code) => highlight(code || '', languages.yaml)}
      className={clsx(className, 'font-mono')}
      padding={10}
      style={{
        fontFamily: '"Fira code", "Fira Mono", monospace',
        fontSize: 14,
        fontSmooth: 'subpixel-antialiased',
      }}
      {...props}
    />
  )
}
