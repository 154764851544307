'use client'
import clsx from 'clsx'
import type { RFC } from 'common-web'
import React from 'react'

import type { ComponentProps } from 'react'
import { useFormContext } from 'react-hook-form'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, Input, useFormField } from '../primitives'

type FieldProps<C extends React.ElementType> = {
  name: string
  label?: string
  className?: any
  description?: string
  placeholder?: string
  type?: string
  required?: boolean
  component?: C
} & ComponentProps<C>

const Field = <C extends React.ElementType = typeof Input>({
  name,
  label,
  description,
  placeholder = undefined,
  component: Component = Input,
  ...props
}: FieldProps<C>) => {
  const form = useFormContext()
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Component placeholder={placeholder} {...props} {...field} />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
        </FormItem>
      )}
    />
  )
}

const Provider: RFC = ({ form, onSubmit, className = undefined, children, ...props }) => {
  return (
    <Form {...form}>
      <form
        className={clsx('w-full space-y-lg', className)}
        autoComplete="off"
        onSubmit={form.handleSubmit(onSubmit)}
        {...props}
      >
        {children}
      </form>
    </Form>
  )
}

export const BetterForm = { Field, Provider }
