import clsx from 'clsx'

import React from 'react'
import { cn } from '../lib'
import { TableBodyCell } from './table-body-cell'
import { TableHeaderCell } from './table-header-cell'

const Table = React.forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(
  ({ className, ...props }, ref) => (
    <div className="w-full h-full">
      <table ref={ref} className={cn('w-full h-full caption-bottom', className)} {...props} />
    </div>
  ),
)

const TableHeader = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <thead ref={ref} className={cn('text-base [&_tr]:border-b', className)} {...props} />
  ),
)

const THead = TableHeader

const TableBody = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <tbody ref={ref} className={cn('[&_tr:last-child]:border-0', className)} {...props} />
  ),
)

const TableFooter = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <tfoot
      ref={ref}
      className={cn('border-t bg-slate-100/50 font-medium [&>tr]:last:border-b-0 dark:bg-slate-800/50', className)}
      {...props}
    />
  ),
)

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & { hoverable?: boolean }
>(({ className, hoverable = false, ...props }, ref) => (
  <tr
    ref={ref}
    className={clsx(
      'border-b data-[state=selected]:bg-slate-100 dark:data-[state=selected]:bg-slate-800',
      { 'hover:bg-gray-50 dark:hover:bg-slate-800/50': hoverable },
      className,
    )}
    {...props}
  />
))

const TableHead = React.forwardRef<HTMLTableCellElement, React.ThHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <th
      ref={ref}
      className={cn(
        'h-10 px-2 text-left align-middle font-medium  [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px] dark:text-slate-400',
        className,
      )}
      {...props}
    />
  ),
)

const TableCell = React.forwardRef<HTMLTableCellElement, React.TdHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <td
      ref={ref}
      className={cn('p-2 align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]', className)}
      {...props}
    />
  ),
)

const TableCaption = React.forwardRef<HTMLTableCaptionElement, React.HTMLAttributes<HTMLTableCaptionElement>>(
  ({ className, ...props }, ref) => (
    <caption ref={ref} className={cn('mt-4 text-slate-500 dark:text-slate-400', className)} {...props} />
  ),
)

const Th = TableHeaderCell
const Td = TableBodyCell
const TBody = TableBody
const Tr = TableRow

const TableShortcuts = {
  THead,
  Td,
  Tr,
  Th,
  TBody,
}

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
  THead,
  Th,
  Td,
  Tr,
  TBody,
  TableShortcuts,
}
