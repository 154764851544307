import clsx from 'clsx'
import type { RFC } from 'common-web'
import { useForm } from 'react-hook-form'
import { BetterForm, LoadingButton, SelectInput, zod, zodResolver } from 'ui/src'

export const step1FormSchema = zod.object({
  sourceId: zod.string(),
  destinationId: zod.string(),
})

export type Step1FormSchema = zod.infer<typeof step1FormSchema>

export const Step1Card: RFC<{
  allSources: Array<any>
  allDestinations: Array<any>
  onSubmit: (values: Step1FormSchema) => void | Promise<void>
  defaultValues: { sourceId?: string | null | undefined; destinationId?: string | null | undefined }
}> = ({
  className = undefined,
  onSubmit,
  defaultValues = { sourceId: undefined, destinationId: undefined },
  allSources = [],
  allDestinations = [],
  ...props
}) => {
  const form = useForm({
    defaultValues: {
      sourceId: defaultValues.sourceId,
      destinationId: defaultValues.destinationId,
    },
    resolver: zodResolver(step1FormSchema),
  })

  const handleSubmit = async (values: Step1FormSchema) => onSubmit(values)

  return (
    <div className={clsx(className)} {...props}>
      <BetterForm.Provider form={form} onSubmit={handleSubmit}>
        <div className="space-y-md">
          <h3>Step 1. Select Contract and Database</h3>
          <div className="grid gap-md grid-cols-8 w-full items-end content-between">
            <div className="col-span-3">
              <BetterForm.Field
                name="sourceId"
                component={SelectInput}
                options={allSources?.map((g) => ({ label: `${g.label} (${g.sourceType})`, value: g.id })) || []}
                label="Source"
              />
            </div>
            <div className="col-span-3">
              <BetterForm.Field
                name="destinationId"
                options={allDestinations?.map((g) => ({ label: g.label, value: g.id })) || []}
                component={SelectInput}
                label="Destination"
              />
            </div>
            <div className="col-span-2">
              <LoadingButton
                disabled={!form.formState.isValid}
                // variant="blue"
                type="submit"
                isLoading={form.formState.isSubmitting}
                className="w-full"
              >
                Show Events
              </LoadingButton>
            </div>
          </div>
        </div>
      </BetterForm.Provider>
    </div>
  )
}
