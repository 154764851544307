import { createAction, createReducer } from '@reduxjs/toolkit'
import type { inferProcedureOutput } from '@trpc/server'
import type { ChainpipeApiRouter } from 'chainpipe-api/src/trpc/_app'
import type { Server as ManagedPostgresServer } from 'models/src/managed-postgres/server.entity'
import YAML from 'yaml'

export type ContractAbiFragmentIndexingClaimsWithViewName = inferProcedureOutput<
  ChainpipeApiRouter['linksRouter']['listContractAbiFragmentIndexingClaims']
>

export type State = {
  sourceId: string | undefined
  destinationId: string | undefined
  contractAbiFragmentIndexingClaims: ContractAbiFragmentIndexingClaimsWithViewName
  managedPostgresServer: ManagedPostgresServer | undefined
  viewPrefix: string | undefined
  code: string | undefined
  isSubmitting: boolean
}

export const initialState: State = {
  sourceId: undefined,
  destinationId: undefined,
  contractAbiFragmentIndexingClaims: [],
  managedPostgresServer: undefined,
  viewPrefix: undefined,
  code: undefined,
  isSubmitting: false,
}

export const actionCreators = {
  setContractAbiFragmentIndexingClaims: createAction<ContractAbiFragmentIndexingClaimsWithViewName>(
    'SET_CONTRACT_ABI_FRAGMENT_INDEXING_CLAIMS',
  ),

  setSourceAndDestinationId: createAction<{ sourceId?: string; destinationId?: string }>(
    'SET_SOURCE_AND_DESTINATION_IDS',
  ),

  setIsSubmitting: createAction<boolean>('SET_IS_SUBMITTING'),
}

export const convertContractAbiFragmentIndexingClaimsWithViewNameToCode = (
  contractAbiFragmentIndexingClaimsWithViewName: ContractAbiFragmentIndexingClaimsWithViewName,
) => {
  const links = contractAbiFragmentIndexingClaimsWithViewName.map((item) => {
    return {
      source: {
        type: 'contract_evm_abi_fragment_indexing_claim',
        id: item.id,
      },
      destination: {
        type: 'managed_postgres_view',
        params: {
          view_name: item.viewName,
        },
      },
    }
  })
  return YAML.stringify({ links })
}

export const reducer = createReducer<State>(initialState, (builder) =>
  builder
    .addCase(actionCreators.setContractAbiFragmentIndexingClaims, (state, action) => {
      // @ts-ignore
      state.contractAbiFragmentIndexingClaims = action.payload
      state.code = convertContractAbiFragmentIndexingClaimsWithViewNameToCode(action.payload)
    })
    .addCase(actionCreators.setSourceAndDestinationId, (state, action) => {
      if (action.payload.destinationId) {
        state.destinationId = action.payload.destinationId
      }
      if (action.payload.sourceId) {
        state.sourceId = action.payload.sourceId
      }
    })
    .addCase(actionCreators.setIsSubmitting, (state, action) => {
      state.isSubmitting = action.payload
    }),
)
