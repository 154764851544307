import zod from 'zod'
import { AllNetworks } from './network-id'

const zodEnum = <T>(arr: T[]): [T, ...T[]] => arr as [T, ...T[]]

const NetworkID = zod.enum(zodEnum(AllNetworks))

export const ZodExtensions = {
  NetworkID,
}
