import { ButtonLink, ContractWithAddressCard, Page } from '@/components'
import { type InferLoaderData, proxyClient } from '@/trpc-client'
import { createColumnHelper } from '@tanstack/react-table'
import { getProjectIdFromCookie } from 'common'
import type { PageWithLoader } from 'common-web'
import { Plus } from 'lucide-react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { BetterTable, Debug, NetworkLabel } from 'ui'

const loader = async () => {
  const projectId = getProjectIdFromCookie(document.cookie)
  const allSources = await proxyClient.sources.evmContractV1.list.query({ projectId })
  return { projectId, allSources }
}

const columnHelper = createColumnHelper<InferLoaderData<typeof loader>['allSources'][number]>()

const columns = [
  columnHelper.display({
    header: 'Contract',
    cell: (info) => {
      const item = info.row.original
      return <ContractWithAddressCard network={item.network} name={item.name} address={item.address} />
    },
  }),

  columnHelper.display({
    header: 'Network',
    cell: (info) => {
      return <NetworkLabel value={info.row.original.network} />
    },
  }),

  columnHelper.display({
    header: 'Created',
    cell: (info) => {
      return <div>{info.row.original.createdAt?.toDateString()}</div>
    },
  }),
  columnHelper.accessor('abiFragmentCount', {
    header: () => <div className="text-right">ABI Fragments</div>,
    cell(props) {
      return <div className="text-right">{props.getValue() || 'None'}</div>
    },
  }),
]

const ListSourcesPage: PageWithLoader = () => {
  const navigate = useNavigate()
  const { allSources } = useLoaderData() as InferLoaderData<typeof loader>

  return (
    <Page
      title="Sources"
      action={
        <ButtonLink to="new" leftIcon={<Plus className="w-4 h-4" />}>
          New Source
        </ButtonLink>
      }
    >
      <BetterTable data={allSources} columns={columns} hoverable={true} onNavigate={navigate}>
        <div className="space-y-lg">
          <div>Sources are how you keep track of things. Add your first source.</div>
          <div>
            <ButtonLink leftIcon={<Plus className="w-5 h-5" />} variant="green" to="/sources/new">
              Add Source
            </ButtonLink>
          </div>
        </div>
      </BetterTable>
    </Page>
  )
}

ListSourcesPage.loader = loader

export default ListSourcesPage
