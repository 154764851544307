import { namedURLs } from '@/lib'
import { type InferLoaderData, proxyClient } from '@/trpc-client'
import type { PageWithLoader } from 'common-web'
import { DestinationTypeId, getProjectIdFromCookie } from 'common/src'
import React from 'react'
import { type Params, useLoaderData, useNavigate } from 'react-router-dom'

const loader = async ({ params }: { params: Params<'id'> }) => {
  const projectId = getProjectIdFromCookie(document.cookie)
  const destinationType = DestinationTypeId.MANAGED_UPSTREAM_POSTGRES
  return { destination: { id: params.id }, projectId, destinationType }
}

const ShowDestinationPage: PageWithLoader = () => {
  const { destination, destinationType } = useLoaderData() as InferLoaderData<typeof loader>
  const navigate = useNavigate()
  React.useEffect(() => {
    if (destinationType === DestinationTypeId.MANAGED_UPSTREAM_POSTGRES) {
      return navigate(`/destinations/${DestinationTypeId.MANAGED_UPSTREAM_POSTGRES}/${destination.id}`)
    } else {
      throw new Error('Unknown destination type')
    }
  }, [destinationType, destination])
  return <div>Loading</div>
}

ShowDestinationPage.loader = loader

export default ShowDestinationPage
