import { trpc } from '@/lib'
import type { NetworkID } from 'common'
import React from 'react'
import { Button, type RFC, Spinner } from 'ui'

type EtherscanImportStatusType = 'ready' | 'progress' | 'done' | 'error'

export const ImportABIFromEtherscanWidget: RFC<{
  network: NetworkID
  address: string
  disabled?: boolean
  onABILoaded: (contractABI: object) => void
}> = ({ network, address, onABILoaded }) => {
  const importABI = trpc.misc.importContractABI.useMutation()
  const [etherscanImportStatus, setEtherscanImportStatus] = React.useState<EtherscanImportStatusType>('ready')
  const handleClickGetFromEtherscan = React.useCallback(() => {
    setEtherscanImportStatus('progress')

    importABI
      .mutateAsync({
        network: network as unknown as 'optimism-mainnet' | 'ethereum-mainnet' | 'base-mainnet',
        address,
      })
      .then((result) => {
        setEtherscanImportStatus('done')
        onABILoaded(result)
      })
      .catch((error) => {
        console.error('error', error)
        setEtherscanImportStatus('error')
      })
  }, [importABI])

  const { label, disabled } = React.useMemo(() => {
    switch (etherscanImportStatus) {
      case 'ready':
        return { label: '⚡ Import ABI from Etherscan', disabled: false }
      case 'done':
        return { label: <span>Imported</span>, disabled: false }
      case 'error':
        return { label: 'Could not import', disabled: false }
      case 'progress':
        return {
          label: (
            <div>
              <Spinner /> <span>Import in Progress</span>
            </div>
          ),
          disabled: true,
        }
    }
  }, [etherscanImportStatus])

  return (
    <Button disabled={disabled} type="button" variant={'outline'} onClick={handleClickGetFromEtherscan}>
      {label}
    </Button>
  )
}
