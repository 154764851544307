import React from 'react'
import type { UseControllerProps } from 'react-hook-form'
import { useController } from 'react-hook-form'
import { Debug } from './debug'
import { FormControl, FormDescription, FormItem, FormLabel } from './form'
import { Input } from './input'

type InputGroupProps<T extends React.ElementType> = {
  name: string
  label?: string
  description?: React.ReactNode | string
  component?: T
} & React.ComponentPropsWithoutRef<T> &
  UseControllerProps

export const InputGroup = React.forwardRef(
  <T extends React.ElementType = typeof Input>(
    {
      name,
      label,
      description,
      component: Component,
      inputProps,
      control,
      rules,
      defaultValue,
      ...props
    }: InputGroupProps<T>,
    ref: React.Ref<any>,
  ) => {
    const {
      field,
      fieldState: { error },
    } = useController({ name, control, rules, defaultValue })

    const Comp = Component || (props.children ? React.Fragment : Input)
    return (
      <FormItem>
        {label && (
          <FormLabel className="flex space-x-2">
            <div>{label}</div>
          </FormLabel>
        )}
        <FormControl>
          <Comp {...field} {...inputProps} ref={ref} {...props} />
        </FormControl>
        {description && <FormDescription>{description}</FormDescription>}
        {error && <div className="text-red-500">{error.message}</div>}
      </FormItem>
    )
  },
)
