import { Page } from '@/components'
import { namedURLs, trpc } from '@/lib'
import { type NetworkID, Networks, SourceTypeId, chainpipeConfig, sqlSlugify } from 'common'
import { Plus } from 'lucide-react'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  AddressInput,
  Column,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  InputGroup,
  LoadingButton,
  NetworkLabel,
  NetworkSelectInput,
  SelectInput,
  Textarea,
  zod,
  zodResolver,
} from 'ui'
import { useProject } from '../../../../contexts'
import { ImportABIFromEtherscanWidget } from './[id]/components/import-abi-from-etherscan-widget'

export const formSchema = zod.object({
  network: zod.enum(chainpipeConfig.enabledNetworks).default('ethereum-mainnet'),
  label: zod.string().min(1, 'Label is required'),
  address: zod.string().length(42, 'please enter 42 characters prefixed with 0x').toLowerCase(),
  abiAsString: zod.string().min(2, 'ABI is required'),
  prefix: zod.string().min(1, 'prefix is required'),
})

const NewEVMContractPage = () => {
  const create = trpc.sources.evmContractV1.create.useMutation()

  const project = useProject()
  const supportedNetworks = trpc.misc.listSupportedNetworks.useQuery()
  const filteredSupportedNetworks = (supportedNetworks.data ?? []).filter(
    (network) => network.value !== Networks['base-goerli'],
  )

  const navigate = useNavigate()

  const onSubmitHandler = React.useCallback(
    async (values: Required<zod.infer<typeof formSchema>>) => {
      const result = await create.mutateAsync({
        projectId: project.id,
        ...values,
      })

      navigate(
        namedURLs.sources.show({
          sourceTypeId: SourceTypeId.EVM_CONTRACT_INDEXING_CLAIM,
          id: result.id,
        }),
      )
    },
    [project && project.id],
  )

  const form = useForm<zod.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      network: 'ethereum-mainnet',
      abiAsString: '[]',
    },
  })

  const handleABILoaded = React.useCallback(
    (abi: object) => form.setValue('abiAsString', JSON.stringify(abi, null, 2)),
    [form.setValue],
  )

  const watch = form.watch()

  React.useEffect(() => {
    const input = watch.label
    if (input) {
      const [slug, error] = sqlSlugify(input)
      if (!error && slug) {
        form.setValue('prefix', slug)
      }
    }
  }, [watch.label])

  return (
    <Page title="Add EVM Contract" narrow>
      <Form {...form}>
        <form
          className="w-full mx-auto grid grid-cols-2 gap-lg"
          autoComplete="off"
          onSubmit={form.handleSubmit(onSubmitHandler)}
        >
          <div className="col-span-2">
            <InputGroup
              label="Network"
              name="network"
              component={NetworkSelectInput}
              options={filteredSupportedNetworks}
            />
          </div>
          <div className="col-span-2">
            <InputGroup
              name="address"
              className="font-mono col-span-2"
              label="Contract Address"
              autoFocus={true}
              component={AddressInput}
              autoComplete="off"
            />
          </div>
          <div className="col-span-1">
            <InputGroup
              name="label"
              label="Contract Label"
              description="A helpful name you can use to refer to this contract"
            />
          </div>
          <div className="col-span-1">
            <InputGroup
              label="Internal Prefix"
              name="prefix"
              className="font-mono"
              description="This prefix will be used internally when referencing this contract"
            />
          </div>
          <div className="col-span-2">
            <FormItem>
              <FormLabel>Contract ABI</FormLabel>
              <br />
              <Column className="w-full space-y-2">
                <ImportABIFromEtherscanWidget
                  address={form.getValues().address}
                  network={form.getValues().network}
                  onABILoaded={handleABILoaded}
                />

                <FormField
                  name="abiAsString"
                  control={form.control}
                  render={({ field }) => (
                    <FormControl className="col-span-1">
                      <Textarea
                        autoComplete="off"
                        placeholder="or paste contract abi here"
                        className="w-full font-mono h-[200px] "
                        {...field}
                      />
                    </FormControl>
                  )}
                />
              </Column>
            </FormItem>
          </div>
          {/* </div> */}
          <div className="col-span-2">
            <LoadingButton
              className="w-full"
              size="lg"
              variant="blue"
              type="submit"
              disabled={!form.formState.isValid}
              isLoading={form.formState.isSubmitting}
              loadingText="Submitting"
              leftIcon={<Plus className="w-4 h-4" />}
            >
              Add Source
            </LoadingButton>
          </div>
        </form>
      </Form>
    </Page>
  )
}

export default NewEVMContractPage
