import type { ActionType, ContractAbiFragmentIndexingProgressType } from 'common/src/chainpipe/redux'

type State = {
  abiFragments: Record<string, ContractAbiFragmentIndexingProgressType>
  evmContract: any
  initialStateHasBeenSet: boolean
}

export const initialState: State = {
  abiFragments: {},
  evmContract: {},
  initialStateHasBeenSet: false,
}

export const reducer = (state: State, action: ActionType): State => {
  if (action.type === 'SET_INITIAL_STATE') {
    return state
  }
  if (action.type === 'SET_ABI_FRAGMENTS') {
    return {
      ...state,
      initialStateHasBeenSet: true,
      abiFragments: action.payload,
    }
  }

  if (action.type === 'UPDATE_PROGRESS') {
    if (!state?.abiFragments || !state.initialStateHasBeenSet) {
      return state
    }
    const progressObject: ContractAbiFragmentIndexingProgressType = state.abiFragments[action.payload.sighash]
    if (!progressObject) {
      console.error(`Could not find record for sighash ${action.payload.sighash}`)
      return state
    }

    const progressUpdateMessage = action.payload

    const deltaProgressRatio = progressUpdateMessage.blockCount / progressObject.requiredBlocks
    const progressRatio = Math.min(1, progressObject.progressRatio + deltaProgressRatio)

    const newProgressObject: ContractAbiFragmentIndexingProgressType = {
      ...progressObject,
      active: progressRatio < 1,
      totalCount: progressObject.totalCount + +progressUpdateMessage.newEventsSeen,
      progressRatio,
    }

    return {
      ...state,
      abiFragments: {
        ...state.abiFragments,
        [action.payload.sighash]: newProgressObject,
      },
    }
  }

  return state
}
