import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import NumberFormat from 'react-number-format'
import { Skeleton } from 'ui'
import type { ContractEventType } from './types'

// hack for now
type ABIParam = any

export const EventLogTable = React.forwardRef<
  React.PropsWithoutRef<HTMLTableElement>,
  {
    items: Array<ContractEventType>
    params: Array<ABIParam>
    isLoadingHead: boolean
  }
>(({ items, params = [], isLoadingHead, ...props }, ref) => {
  const columnHelper = createColumnHelper<ContractEventType>()
  const columns = [
    columnHelper.group({
      header: 'Event Params',
      columns: params.map((param, index) =>
        columnHelper.accessor((row) => row.inputs, {
          id: index.toString(),
          header(_props) {
            return (
              <div className="font-mono font-bold">
                {param.name} <em className="text-slate-500">{param.type}</em>
              </div>
            )
          },
          cell: (info) => {
            const obj = info.cell.row.original.inputs
            if (!obj) {
              return <div />
            }
            const value = obj[index.toString()]

            let formattedValue
            if (typeof value === 'string') {
              formattedValue = value
            } else {
              formattedValue = JSON.stringify(value)
            }

            return <div className="font-mono">{formattedValue}</div>
          },
        }),
      ),
    }),

    columnHelper.group({
      header: 'Block Info',
      columns: [
        columnHelper.accessor((row) => row.blockNumber, {
          id: 'blockNumber',
          header: 'Number',
          cell: (info) => {
            const val = info.getValue()
            return <NumberFormat thousandSeparator="," displayType="text" value={val} />
          },
        }),

        columnHelper.accessor((row) => row.logIndex, {
          header: 'Log Index',
          cell(info) {
            const value = info.getValue()
            return <div className="font-mono">{value}</div>
          },
        }),

        columnHelper.accessor((row) => row.transactionIndex, {
          cell(info) {
            return info.getValue()
          },
          header: 'Transaction Index',
        }),

        columnHelper.accessor((row) => row.blockHash, {
          id: 'block_hash',
          header: 'Block Hash',
          cell: (info) => {
            const value = info.getValue()
            return <div className="font-mono">{value}</div>
          },
        }),
      ],
    }),
  ]

  const table = useReactTable({
    columns,
    data: items,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <table className="table" ref={ref} {...props}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} colSpan={header.colSpan} className="p-2 whitespace-nowrap border-b border-slate-200">
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {!isLoadingHead && items.length === 0 ? (
        <tbody className="max-h-12">
          <tr>
            <td colSpan={4} className="text-center p-lg text-slate-700">
              No Data
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="border-b border-slate-200">
                  {isLoadingHead ? (
                    <Skeleton className="h-6" />
                  ) : (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      )}
    </table>
  )
})
