const chromatic = '4269d0efb118ff725c6cc5b03ca951ff8ab7a463f297bbf59c6b4e9498a0'

const colors = (specifier: string) => {
  let n = (specifier.length / 6) | 0,
    colors = new Array(n),
    i = 0
  while (i < n) colors[i] = '#' + specifier.slice(i * 6, ++i * 6)
  return colors
}

export const chromaticScale = colors(chromatic)
