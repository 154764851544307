import React from 'react'
import clsx from 'clsx'

import { forwardRef } from 'react'
import { twMerge } from '../lib'

type ColumnProps = React.HTMLProps<HTMLDivElement> & {
  className?: string
  children: React.ReactNode
  center?: boolean
}

export const Column = forwardRef<HTMLDivElement, ColumnProps>(
  ({ className, children, center = false, ...props }, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={clsx(
          'flex w-full flex-col',
          {
            'items-start': !center,
            'items-center': center,
          },
          className,
        )}
      >
        {children}
      </div>
    )
  },
)
