import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { BetterForm, Button, type RFC, useCenterAbsoluteUrl } from 'ui'
import { trpc } from '../../lib'

const SignupForm: RFC<{ key: string; email: string }> = ({ key, email }) => {
  const form = useForm({
    defaultValues: {
      email,
      password: '',
    },
  })

  const mutation = trpc.invites.accept.useMutation({})
  const _navigate = useNavigate()
  const loginUrl = useCenterAbsoluteUrl({ subdomain: 'auth', path: '/login' })

  const handleSubmit = React.useCallback(
    (values: { email: string; plaintextPassword: string }) => {
      return mutation
        .mutateAsync({
          key,
          plaintextPassword: values.plaintextPassword,
        })
        .then((result) => {
          if (result.success) {
            window.location.href = loginUrl
          } else {
            // TODO: show an error state
          }
        })
    },
    [key, mutation, loginUrl],
  )

  return (
    <div className="p-4 space-y-2">
      <h1>Welcome</h1>
      <BetterForm.Provider onSubmit={handleSubmit} form={form}>
        <BetterForm.Field label="Email Address" disabled={true} name="email" />
        <BetterForm.Field autoFocus label="Password" type="password" name="plaintextPassword" />
        <Button type="submit">Sign Up</Button>
      </BetterForm.Provider>
    </div>
  )
}

const AcceptInvitationPage = () => {
  const { key } = useParams()
  if (!key) {
    // error
    throw new Error('no invite')
  }

  const result = trpc.invites.check.useQuery({ key })

  if (result.isLoading) {
    return <div>Loading</div>
  } else if (result.data && !result.data.found) {
    return <div>Invite Not Found</div>
  } else if (result.data?.email && result.data.key) {
    return <SignupForm key={result.data.key} email={result.data.email} />
  }
}

export default AcceptInvitationPage
