import { Page } from '@/components'
import { trpc } from '@/lib'
import type { InferLoaderData } from '@/trpc-client'
import { createColumnHelper } from '@tanstack/react-table'
import type { PageWithLoader } from 'common-web'
import { getProjectIdFromCookie } from 'common/src'
import React, { useMemo } from 'react'
import { type Params, useLoaderData, useNavigate } from 'react-router-dom'
import { CopyIcon, Debug, EditableLabel, type RFC, betterToast, betterToast as toast, useCopyToClipboard } from 'ui'
import { DescriptionTable } from 'ui'

const columnHelper = createColumnHelper()

const columns = [
  columnHelper.display({
    header: 'Source',
    id: 'source',
    cell(info) {
      const item = info.row.original
      return <Debug value={item} />
    },
  }),
]

async function loader({ params }: { params: Params<'id'> }) {
  const projectId = getProjectIdFromCookie(document.cookie)

  // const _link = await proxyClient.linksRouter.show.query({ id: params.id!, projectId })
  return {
    projectId,
    id: params.id,
    params,
  }
}
const tabs = [
  { label: 'Linked Sources', id: 'sources' },
  { label: 'Tables', id: 'tables' },
] as const

const ShowPostgresDestination: PageWithLoader = () => {
  const { id, projectId, params } = useLoaderData() as InferLoaderData<typeof loader>
  const currentTab = new URLSearchParams(location.search).get('tab') || tabs[0].id
  const selectedIndex = useMemo(() => tabs.findIndex((tab) => tab.id === currentTab), [currentTab])

  const { data, refetch: refetchDb } = trpc.destinations.managedPostgres.show.useQuery(
    {
      id: id as string,
      projectId,
    },
    {
      enabled: !!id,
    },
  )

  const handleUpdate = trpc.destinations.managedPostgres.update.useMutation()

  // const { data: linkedSources } = trpc.linksRouter.listSourceGroupItemsForDestinationGroup.useQuery(
  //   { id, projectId },
  //   { enabled: !!id && !!projectId },
  // )

  const linkedSources: Array<any> = []

  const items = {
    Name: data?.label,
    'Connection Type': 'Postgres',
    'Database URL': data?.databaseUrl && <ClickToCopyUrl value={data.databaseUrl} />,
  }

  const handleNameChange = React.useCallback(
    (name: string) => {
      return handleUpdate.mutateAsync({ id: id as string, projectId, name }).then(() => {
        betterToast.success('Name updated')
        refetchDb()
      })
    },
    [params.id, projectId],
  )

  const handleTabChange = (tabId: string) => {
    navigate({
      pathname: location.pathname,
      search: `?tab=${tabId}`,
    })
  }

  const navigate = useNavigate()
  return (
    <Page
      className="space-y-xl"
      titleText={'Postgres Managed DB'}
      title={
        data?.label && (
          <EditableLabel className="w-full text-xl" defaultValue={data.label} onChange={handleNameChange} />
        )
      }
    >
      <DescriptionTable items={items} />

      {/* <Tab.Group selectedIndex={selectedIndex} onChange={(index) => handleTabChange(tabs[index].id)}>
        <div className="-mx-lg">
          <Tab.List as={TabNav.Menu} className="px-lg">
            {tabs.map((tab, index) => (
              <Tab
                as={TabNav.Tab}
                selected={selectedIndex === index}
                key={tab.id}
                className="focus:outline-none text-sm"
              >
                {tab.label}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel id="sources" className={'px-lg py-md'}>
              <Row>
                <h3>Linked Sources</h3>
                <ButtonLink to={`/links/new?destinationGroupId=${id}`} leftIcon={<Plus className="w-4 h-4" />}>
                  Link Source
                </ButtonLink>
              </Row>
              <BetterTable data={linkedSources} hoverable onNavigate={navigate} columns={columns}>
                Linked sources will appear here.
              </BetterTable>
            </Tab.Panel>
            <Tab.Panel id="tables" className={'px-lg py-md'}></Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group> */}
    </Page>
  )
}

const ClickToCopyUrl: RFC<{ value: string }> = ({ value }) => {
  const { copy } = useCopyToClipboard(value || '')
  const handleCopy = React.useCallback(() => {
    copy()
    toast.success('Database URL copied to clipboard')
  }, [copy])

  return (
    <div
      className="flex subpixel-antialiased w-full justify-between items-center hover:bg-gray-50 active:shadow-inner transition-all cursor-pointer font-ibm rounded-lg border px-4 py-2 border-gray-200 overflow-hidden"
      onClick={handleCopy}
    >
      <div className="overflow-ellipsis select-none font-mono">{value}</div>
      <CopyIcon />
    </div>
  )
}
ShowPostgresDestination.loader = loader
export default ShowPostgresDestination
