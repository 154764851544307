import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import { z } from 'zod'

const CENTER_TRACING_ELEMENT_ID = 'center-frontend-monitoring'
/**
 * Remember to keep this in sync with packages/chainpipe-web/index.html
 */
const SENTINEL_REPLACEMENT_VALUE = '%CENTER_MONITORING_JSON%'

const MonitoringSchema = z.object({
  gitCommitSha: z.string(),
  sentryDsn: z.string(),
  sentrySampleRate: z.number().default(1.0),
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  sentryReplaysSessionSampleRate: z.number().default(0.1),
  sentryReplaysOnErrorSampleRate: z.number().default(1.0),
  sentryEnableReplayIntegration: z.boolean().default(true),
  sentryEnvironment: z.string().default('development'),
})

type IMonitoringSchema = z.infer<typeof MonitoringSchema>

function getContentsOfTracingElement(): IMonitoringSchema | null {
  const tracingElement = document.getElementById(CENTER_TRACING_ELEMENT_ID)
  if (!tracingElement) {
    console.log('Tracing element not found')
    return null
  }
  const tracingElementContents = tracingElement.innerText
  if (!tracingElementContents) {
    console.log('Tracing element has no contents')
    return null
  }

  const tracingElementTextMatchesReplacementValue = tracingElementContents === SENTINEL_REPLACEMENT_VALUE
  if (tracingElementTextMatchesReplacementValue) {
    console.log('Tracing element only contains the replacement sentinel value')
    return null
  }

  try {
    const parsedTracingElementJson = JSON.parse(tracingElementContents)
    const monitoringSchemaValidationResult = MonitoringSchema.safeParse(parsedTracingElementJson)
    if (monitoringSchemaValidationResult.success) {
      return monitoringSchemaValidationResult.data
    } else {
      console.log('Tracing element failed content validation', monitoringSchemaValidationResult)
      return null
    }
  } catch (_err) {
    console.log('Tracing element failed to parse JSON')
    return null
  }
}

/**
 * This can always be disabled by setting the sentryDsn or any other required variable
 * to false
 */
function initSentry({
  gitCommitSha,
  sentryDsn,
  sentrySampleRate,
  sentryReplaysOnErrorSampleRate,
  sentryReplaysSessionSampleRate,
  sentryEnableReplayIntegration,
  sentryEnvironment,
}: IMonitoringSchema) {
  const optionalIntegrations = [
    sentryEnableReplayIntegration ? Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }) : undefined,
  ].filter(Boolean) as any[]

  const sentryConfig: Sentry.BrowserOptions = {
    dsn: sentryDsn,
    release: gitCommitSha,
    environment: sentryEnvironment,
    integrations: [
      Sentry.browserTracingIntegration(),
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      ...optionalIntegrations,
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: sentrySampleRate,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/.*\.center.*\.app/],

    replaysSessionSampleRate: sentryReplaysSessionSampleRate,
    replaysOnErrorSampleRate: sentryReplaysOnErrorSampleRate,
  }
  console.log('Initializing sentry with configuration', sentryConfig)
  Sentry.init(sentryConfig)
}

export function wireUpFrontendTracing() {
  const monitoringConfig = getContentsOfTracingElement()
  if (!monitoringConfig) {
    console.log('Skipping monitoring setup')
    return null
  }
  initSentry(monitoringConfig)
}
