import React from 'react'
import { cn } from '../lib'

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  error?: string
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, error = undefined, ...props }, ref) => {
    return (
      <>
        <textarea
          className={cn(
            'bg-white text-gray-900 placeholder:text-gray-500 placeholder:text-muted-foreground flex min-h-[80px] w-full rounded-lg border px-[.88rem] py-3 text-base focus-visible:ring-ring focus-visible:outline-none focus-visible:ring-2 disabled:cursor-not-allowed disabled:bg-gray-50',
            typeof error !== 'undefined'
              ? 'border-red-300 focus-visible:border-red-300 focus-visible:ring-red-100'
              : 'border-gray-300 focus-visible:border-brand-primary focus-visible:ring-brand-focus',
            className,
          )}
          ref={ref}
          {...props}
        />
        {typeof error !== 'undefined' && <p className="text-red-500 text-sm leading-5 mt-xs">{error}</p>}
      </>
    )
  },
)
