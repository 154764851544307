import { QueryClientProvider } from '@tanstack/react-query'
import { useCenterTrpcClient } from 'common-web'
import { RouterProvider } from 'react-router-dom'
import { BetterToaster as Toaster, betterToast as toast } from 'ui'
import { trpc } from './lib/trpc'
import { router } from './router'
import { trpcClient } from './trpc-client'

export const DashboardApp = () => {
  // const onError = (err: any) => showError({ title: 'Error', message: JSON.stringify(err) })
  // const [error, setError] = React.useState(null)

  const queryClient = useCenterTrpcClient({
    onError: (err) => {
      toast.error(err.title || err.message || 'Something went wrong.')
    },
  })

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <Toaster />
      </QueryClientProvider>
    </trpc.Provider>
  )
}

// // REGISTER ERROR OVERLAY
// const showErrorOverlay = (err: TRPCError) => {
//   const ErrorOverlay = customElements.get('vite-error-overlay')
//   if (!ErrorOverlay) {
//     return
//   }
//   console.log(err)
//   const overlay = new ErrorOverlay({
//     message: err.message || err.code,
//     stack: err.stack,
//     name: err.name,
//     code: err.code,
//   })
//   document.body.appendChild(overlay)
// }

// window.addEventListener('error', (err) => showErrorOverlay(err.error))
// window.addEventListener('unhandledrejection', (err) => showErrorOverlay(err.reason))
