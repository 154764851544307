import { parseNetworkID } from './network-id'

export const namedContracts = {
  [parseNetworkID('ethereum-mainnet')]: {
    AdidasOriginals: '0x28472a58a490c5e09a238847f66a68a47cc76f0f',
    Azuki: '0xed5af388653567af2f388e6224dc7c4b3241c544',
    BAYC: '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d',
    CryptoArte: '0xbace7e22f06554339911a03b8e0ae28203da9598',
    CryptopunksData: '0x16f5a35647d6f03d5d3da7b35409d65ba03af3b2',
    CryptopunksRegistry: '0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb',
    DeGods: '0x8821bee2ba0df28761afff119d66390d594cd280',
    Doodles: '0x8a90cab2b38dba80c64b7734e58ee1db38b8992e',
    ENSNameWrapper: '0xd4416b13d2b3a9abae7acd5d6c2bbdbe25686401',
    ENSRegistry: '0x00000000000c2e074ec69a0dfb2997ba6c7d2e1e',
    ENSResolver: '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85',
    Milady: '0x5Af0D9827E0c53E4799BB226655A1de152A425a5'.toLowerCase(),
    Moonbirds: '0x23581767a106ae21c074b2276d25e5c3e136a68b',
    OpenseaSharedStorefront: '0x5e30b1d6f920364c847512e2528efdadf72a97a9',
    Nouns: '0x9c8ff314c9bc7f6e59a9d9225fb22946427edc03',
    AudioContract: '0xcbc67ea382f8a006d46eeeb7255876beb7d7f14d',
    RandomArweaveHosted: '0xefdbe9a86a0ccdf905e566a6ca809b85a3214ffc',
    USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  },
} as const
