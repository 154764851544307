import React from 'react'
import { Plus } from 'lucide-react'

import { clsx } from '../lib'
import { Button, type ButtonProps } from '../primitives/button'

import { forwardRef } from 'react'

export const AddButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className = undefined, children = undefined, ...props }, ref) => {
    return (
      <Button leftIcon={<Plus className="w-4 h-4" />} className={clsx(className)} ref={ref} {...props}>
        {children}
      </Button>
    )
  },
)
