import type { NetworkID } from 'common'
import type React from 'react'
import { NetworkIcon, type RFC, Td, clsx } from 'ui'

export const ContractWithAddress: RFC<{
  address: string
  network: NetworkID
  name: string
  component?: React.ComponentType | 'div'
}> = ({ className = undefined, children = undefined, address, network, name, component: Component = Td, ...props }) => {
  return (
    <Component className={clsx('space-y-2', className)} {...props}>
      <div className="font-medium">{name}</div>
      <div className="font-mono text-slate-500 text-sm">{address}</div>
    </Component>
  )
}

export const ContractWithAddressCard: RFC<{
  address: string
  network?: NetworkID
  name: string
}> = ({ className = undefined, children = undefined, address, network = undefined, name, ...props }) => {
  return (
    <div className={clsx('space-y-1', className)} {...props}>
      <div className="flex space-x-sm items-center">
        <div className="font-medium">{name}</div>
        {network && <NetworkIcon network={network} size="sm" />}
      </div>
      <div className="font-mono text-slate-500">{address}</div>
    </div>
  )
}

export const EventWithSighashCard: RFC<{ name: string; sighash: string }> = ({
  className = undefined,
  children = undefined,
  name,
  sighash,
  ...props
}) => {
  return (
    <div className={clsx('space-y-2', className)} {...props}>
      <div className="font-medium text-base subpixel-antialiased font-mono tracking-tight leading-tight">{name}</div>
      <div className="font-mono tracking-tight text-base text-light line-clamp">{sighash}</div>
    </div>
  )
}
