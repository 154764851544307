import { ButtonLink, Page } from '@/components'
import { ProjectContext } from '@/contexts'
import { useContext } from 'react'
import { Card, CardGrid } from 'ui'

const ProjectsIndexPage = () => {
  const { projects } = useContext(ProjectContext)
  return (
    <Page
      title="Projects"
      description="Projects are how you organize resources"
      action={<ButtonLink to="/projects/new">New Project</ButtonLink>}
      requiresProject={false}
    >
      <CardGrid>
        {projects.map((project) => (
          <Card>{project.name}</Card>
        ))}
      </CardGrid>
    </Page>
  )
}

export default ProjectsIndexPage
