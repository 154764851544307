'use client'
import clsx from 'clsx'
import React from 'react'
import { forwardRef, useCallback, useEffect, useState } from 'react'

interface EditableLabelProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  defaultValue: string
  onChange: (newValue: string) => void
  className?: string
}

export const EditableLabel = forwardRef<HTMLInputElement, EditableLabelProps>(
  ({ defaultValue, onChange, className, ...props }, ref) => {
    useEffect(() => {
      // To refresh the component if default value changes
    }, [defaultValue])
    const [currentText, setCurrentText] = useState(defaultValue)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentText(e.target.value)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const target = e.target as HTMLInputElement
        onChange(target.value)
        // e.stopPropagation()
        // target.blur()
      } else if (e.key === 'Escape') {
        setCurrentText(defaultValue)
        ;(e.target as HTMLInputElement).blur()
      }
    }

    const handleInputBlur = useCallback(() => {
      if (currentText !== defaultValue) {
        onChange(currentText)
      }
    }, [defaultValue])

    return (
      <input
        type="text"
        value={currentText}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleInputBlur}
        className={clsx(
          'w-full cursor-pointer px-0 py-0 transition-all duration-75 p-2 text-inherit ring-0 focus:outline-none active:outline-none focus:ring-0 bg-transparent',
          'border-transparent',
          'hover:border-gray-300 hover:bg-slate-100/50', // Gray border on hover
          'focus:border-blue-300/50 focus:shadow-sm', // Blue border when active
          className,
        )}
        ref={ref}
        {...props}
      />
    )
  },
)
