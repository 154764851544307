import * as React from 'react'
import { Link, type LinkProps } from 'react-router-dom'
import { Button, type ButtonProps } from 'ui'

const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonProps & { to: string; target?: '_self' | '_blank' }>(
  ({ to, target, children, ...props }, ref) => {
    return (
      <Link draggable={false} to={to} target={target}>
        <Button ref={ref} {...props}>
          {children}
        </Button>
      </Link>
    )
  },
)

export { ButtonLink }
