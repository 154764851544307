export interface ICommonPlanProps {
  name: string
  description: string
  recommended?: true
  price: {
    // TODO: Get rid of this and look it up instead from the stripe price id
    amountInDollars: number
    stripeId: { dev: string; production: string }
    term: 'month'
  } | null
  slug: 'free' | 'builder' | 'enterprise'
}

export type IValidPlanSlugs = ICommonPlanProps['slug']

export const plans = {
  free: {
    name: 'Free',
    description: 'Start building today, no credit card required',
    price: {
      stripeId: {
        dev: 'price_1OXmSfK8KDBmwZhEaHBkwJKu',
        production: 'price_1LxKE2K8KDBmwZhEKXXgDxNV',
      },
      amountInDollars: 0,
      term: 'month',
    },
  },
  builder: {
    name: 'Builder',
    description: 'Best plan for growing projects',
    recommended: true,
    price: {
      stripeId: {
        dev: 'price_1OXmRIK8KDBmwZhEXD46oXvA',
        production: 'price_1MtezsK8KDBmwZhEyNiQMJuX',
      },
      amountInDollars: 1,
      term: 'month',
    },
  },
  enterprise: {
    name: 'Enterprise',
    description: 'Everything that a fully scaled project needs',
    price: null,
  },
} as const satisfies {
  [slug in IValidPlanSlugs]: Omit<ICommonPlanProps, 'slug'>
}

type IPlansWithSlug = {
  [Slug in IValidPlanSlugs]: (typeof plans)[Slug] & { slug: Slug }
}
export const plansWithSlug: IPlansWithSlug = Object.fromEntries(
  // @ts-ignore might have an error
  Object.entries(plans).map(([slug, planDetails]: [IValidPlanSlugs, (typeof plans)[IValidPlanSlugs]]) => {
    return [slug, { slug, ...planDetails }]
  }),
) as IPlansWithSlug
