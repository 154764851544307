import { createTRPCReact, type inferReactQueryProcedureOptions } from '@trpc/react-query'
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server'
import type { ChainpipeApiRouter } from 'chainpipe-api/src/trpc/_app'

export const trpc = createTRPCReact<ChainpipeApiRouter>({})

/**
 * @see https://trpc.io/docs/client/react/infer-types
 *
 */
export type ReactQueryOptions = inferReactQueryProcedureOptions<ChainpipeApiRouter>

/**
 * @see https://trpc.io/docs/client/react/infer-types
 *
 * @example RouterInputs['waitlist']['create']
 *
 */
export type RouterInputs = inferRouterInputs<ChainpipeApiRouter>

/**
 * @see https://trpc.io/docs/client/react/infer-types
 *
 * Sometimes you might need to exclude types to get at what you want
 *
 * @example type ICreateProjectInputs = RouterInputs['dashboard']['projects']['create']
 * @example type UserType = Exclude<RouterOutputs['auth']['whoami']['user'], null>
 * @example type ProjectType = Exclude<RouterOutputs['auth']['whoami']['projects'], never[]>[number]
 */
export type RouterOutputs = inferRouterOutputs<ChainpipeApiRouter>
