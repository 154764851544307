import type { ColumnDef } from '@tanstack/react-table'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import clsx from 'clsx'
import type { PropsCC } from 'common-web'
import React from 'react'
import { useCallback, useMemo } from 'react'

type TableItem = Record<string, any> & { url?: string }

export const BetterTable = <T extends TableItem>({
  className = undefined,
  data = [],
  columns = [],
  onNavigate = undefined,
  hoverable = false,
  children = 'No Data',
  isLoading = false,
  style = {},
}: PropsCC<{
  data: Array<T>
  columns: Array<ColumnDef<T | any>>
  onNavigate?: (url: string) => void | Promise<void>
  hoverable?: boolean
  children?: React.ReactNode
  isLoading?: boolean
  style?: any
}>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 200,
    },
  })

  const onRowClick = useCallback(
    (url: string) => {
      if (onNavigate && url) {
        onNavigate(url)
      }
    },
    [onNavigate],
  )

  const isEmpty = useMemo(() => {
    return !data || data.length === 0
  }, [data])

  return (
    <table style={style} className={clsx('w-full', className)}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="border-b border-slate-200">
            {headerGroup.headers.map((header) => (
              <th className="p-4 select-none text-slate-700" key={header.id}>
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            className={clsx('border-t border-slate-10 last:border-b select-none', {
              'hover:bg-slate-100 active:bg-slate-200/50 cursor-pointer': hoverable,
            })}
            onClick={() => onRowClick(row.original.url)}
          >
            {row.getVisibleCells().map((cell) => (
              <td className="p-4" key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      {isEmpty && !isLoading && (
        <tbody>
          <tr>
            <td colSpan={columns.length} className="text-center py-xl bg-slate-50 text-slate-500  p-4 select-none">
              {children}
            </td>
          </tr>
        </tbody>
      )}
    </table>
  )
}
