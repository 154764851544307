import { Page, useProjectId } from '@/components'
import { trpc } from '@/lib'
import { type InferLoaderData, proxyClient } from '@/trpc-client'
import clsx from 'clsx'
import type { PageWithLoader, RFC } from 'common-web'
import { getProjectIdFromCookie } from 'common/src'
import { type Params, useLoaderData } from 'react-router-dom'
import { Debug } from 'ui'

async function loader({ params }: { params: Params<'id'> }) {
  const projectId = getProjectIdFromCookie(document.cookie)
  const link = await proxyClient.linksRouter.show.query({ id: params.id!, projectId })
  return {
    id: params.id,
    projectId,
    link,
  }
}

const ShowLinkPage: PageWithLoader = ({ className = undefined, ...props }) => {
  const { projectId, id, link } = useLoaderData() as InferLoaderData<typeof loader>
  return (
    <Page title="Link" className={clsx(className)} {...props}>
      <Debug value={link} />
    </Page>
  )
}
ShowLinkPage.loader = loader
export default ShowLinkPage
