import { trpc } from '@/lib/trpc'
import {
  type ActionType,
  type ContractAbiFragmentIndexingProgressType,
  createSetAbiFragmentsAction,
} from 'common/src/chainpipe/redux'
import React from 'react'
import { initialState, reducer } from './redux'

export const useEvmContractDetail = ({
  projectId,
  contractId,
}: {
  projectId: string
  contractId: string | undefined
}) => {
  const { data: evmContract } = trpc.sources.evmContractV1.show.useQuery(
    {
      projectId,
      id: contractId!,
    },
    {
      enabled: !!projectId && !!contractId,
    },
  )

  const [state, dispatch] = React.useReducer(reducer, initialState)

  const trpcUtils = trpc.useUtils()

  React.useEffect(() => {
    trpcUtils.sources.evmContractV1.abiFragmentIndexingClaims.listWithCounts
      .fetch({
        projectId,
        id: contractId as string,
      })
      .then((result) => {
        dispatch(createSetAbiFragmentsAction(result))
      })
  }, [])

  trpc.sources.evmContractV1.abiFragmentIndexingClaims.watch.useSubscription(
    {
      projectId,
      contractClaimId: contractId as string,
    },
    {
      onData(data: ActionType | any) {
        if (data?.type === 'UPDATE_PROGRESS') {
          dispatch(data as ActionType)
        }
      },
      enabled: !!contractId,
    },
  )

  return {
    evmContract: evmContract
      ? {
          id: evmContract.id,
          label: evmContract.label,
          prefix: evmContract.prefix,
          address: evmContract.address,
          network: evmContract.network,
        }
      : {},
    abiFragmentCoverages: state
      ? (Object.values(state.abiFragments) as Array<ContractAbiFragmentIndexingProgressType>)
      : [],
    isLoading: !state.initialStateHasBeenSet,
  }
}
