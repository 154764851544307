import type { LinkType } from './types'

export const indexingLinks: Array<LinkType> = [
  {
    title: 'Sources',
    // icon: undefined,
    to: 'sources',
    matchIf(url) {
      return url.startsWith('/sources')
    },
  },

  // {
  //   title: 'Add New Source',
  //   // icon: LucidePlus,
  //   to: 'sources/new',
  //   matchIf(url) {
  //     return url.startsWith('/sources/new')
  //   },
  // },

  {
    title: 'Destinations',
    // icon: undefined,
    to: 'destinations',
    matchIf: (url) => {
      return url.startsWith('/destinations')
    },
  },
  {
    title: 'Links',
    // icon: undefined,
    to: 'links',
    matchIf: (url) => {
      return url.startsWith('/links')
    },
  },
  // {
  //   title: 'Transforms',
  //   // icon: undefined,
  //   to: 'transforms',
  //   matchIf: (url) => {
  //     return url.startsWith('/transforms')
  //   },
  // },
  // {
  //   title: 'Add New Destination',
  //   icon: undefined,
  //   to: 'destinations/new',
  //   matchIf: (url) => {
  //     return (
  //       url.startsWith('/destinations') &&
  //       (url.endsWith('new') || url.endsWith(`new/${DestinationEnumType.POSTGRES_FDW}`))
  //     )
  //   },
  // },
]

export const apiLinks: Array<LinkType> = [
  {
    title: 'Overview',
    to: '/api-overview',
  },
  {
    title: 'API Keys',
    to: '/api-keys',
    matchIf: (url: string) => {
      return url.startsWith('/api-keys')
    },
  },
]

export const pipesLinks: Array<LinkType> = [
  {
    title: 'Pipes',
    // icon: undefined,
    to: 'pipes',
  },
  {
    title: 'Add New Pipe',
    // icon: undefined,
    to: 'pipes/new',
  },
]

export const settingsLinks: Array<LinkType> = [
  // {
  //   title: 'Usage',
  //   to: '/usage',
  // },
  {
    title: 'Project',
    to: '/settings',
  },
  {
    title: 'Plan',
    to: '/plan',
  },
  {
    title: 'Team',
    to: '/team',
  },
]
