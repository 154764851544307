import { ButtonLink, Page } from '@/components'
import { useProject, useProjectId } from '@/contexts/project-context'
import { trpc } from '@/lib'
import { Plus } from 'lucide-react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { ApiKeyContent, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'ui'

const ApiKeysPage = () => {
  const projectId = useProjectId()
  const { data: apiKeys } = trpc.dashboard.apiKeys.list.useQuery(
    {
      projectId,
    },
    {
      placeholderData: [],
    },
  )
  const navigate = useNavigate()

  return (
    <Page
      title="API Keys"
      action={
        <ButtonLink leftIcon={<Plus className="w-4 h-4" />} to="new">
          New API Key
        </ButtonLink>
      }
    >
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[25%]">Name</TableHead>
            <TableHead className="w-[35%]">Key</TableHead>
            <TableHead className="w-[25%]">Origins</TableHead>
            <TableHead className="w-[15%] text-right">Edit</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {(apiKeys || []).map((apiKey) => (
            <TableRow className="cursor-pointer" key={apiKey.id} onClick={() => navigate(`/api-keys/${apiKey.id}`)}>
              <TableCell className="font-medium">{apiKey.name}</TableCell>
              <TableCell>
                <ApiKeyContent className="text-sm" value={apiKey.key} />
              </TableCell>
              <TableCell>{apiKey.origins}</TableCell>
              <TableCell className="text-right">
                <Link to={`/api-keys/${apiKey.id}`}>Edit</Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Page>
  )
}

export default ApiKeysPage
