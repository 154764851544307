import { trpc } from '@/lib/trpc'
import { bindActionCreators, createAction, createReducer } from '@reduxjs/toolkit'
import type { NetworkID } from 'common'
import type { ActionType, ActionTypes } from 'common/src/chainpipe/redux'
import sortBy from 'lodash/sortBy'
import React, { useMemo, useReducer } from 'react'

/**
 * items functions as a two-sided list. Head and tail, block number desc.
 */
type State = {
  items: Array<any>
  lastUpdatedAt: Date | null
  contract: Partial<{
    network: NetworkID
    address: string
    name: string
  }>
  abiFragment: Partial<{
    sighash: string
    fullName: string
  }>
  head: number
  tail: number
  isLoadingHead: boolean
  isLoadingTail: boolean
  newEventsSeen: number
}

const initialState: State = {
  items: [],
  lastUpdatedAt: null,
  contract: {},
  abiFragment: {},
  /**
   * The highest block number
   */
  head: -Infinity,
  /**
   * The lowest block number
   */
  tail: Infinity,
  isLoadingHead: true,
  isLoadingTail: false,
  newEventsSeen: 0,
}

const actionCreators = {
  /**
   * Updates the total number of events seen.
   */

  setIsLoading: createAction<{ isLoading: boolean }, 'SET_IS_LOADING'>('SET_IS_LOADING'),

  refreshItems: createAction<Pick<State, 'items'>, 'REFRESH_ITEMS'>('REFRESH_ITEMS'),

  setContract: createAction<Pick<State, 'contract'>, 'SETUP_CONTRACT'>('SETUP_CONTRACT'),

  setAbiFragment: createAction<Pick<State, 'abiFragment'>, 'SETUP_ABI_FRAGMENT'>('SETUP_ABI_FRAGMENT'),

  setIsLoadingAtTail: createAction<boolean, 'SET_IS_LOADING_AT_TAIL'>('SET_IS_LOADING_AT_TAIL'),

  // prettier-ignore
  updateProgress: createAction<ActionTypes['UpdateProgress']['payload'], 'UPDATE_PROGRESS'>('UPDATE_PROGRESS'),
}

type EventLogActions = typeof actionCreators

const eventLogsReducer = createReducer<State>(initialState, (builder) =>
  builder
    .addCase(actionCreators.setContract, (state, action) => {
      state.contract = action.payload.contract
    })
    .addCase(actionCreators.setAbiFragment, (state, action) => {
      state.abiFragment = action.payload.abiFragment
    })
    .addCase(actionCreators.setIsLoading, (state, action) => {
      state.isLoadingHead = action.payload.isLoading
      state.items = Array(10).fill({})
    })
    .addCase(actionCreators.refreshItems, (state, action) => {
      const allItems = sortBy(action.payload.items, (i) => -1 * i.blockNumber)
      state.items = allItems
      if (allItems.length > 0) {
        state.head = allItems[0].blockNumber
        state.tail = allItems[allItems.length - 1].blockNumber
      }
      state.lastUpdatedAt = new Date()
      state.isLoadingHead = false
      state.newEventsSeen = 0
    })
    .addCase(actionCreators.setIsLoadingAtTail, (state, action) => {
      state.isLoadingTail = action.payload
    })
    .addCase(actionCreators.updateProgress, (state, action) => {
      state.newEventsSeen += +action.payload.newEventsSeen
      // if (action.payload.blockRange) {
      //   const parts = action.payload.blockRange.split(',')
      //   if (parts[1]) {
      //     const tip = +parts[1].replace(')', '').replace(']', '')
      //     if (Number.isFinite(tip)) {
      //       // state.head = Math.max(state.head, tip)
      //     }
      //   }
      // }
    }),
)

interface Options {
  contractId: string
  abiFragmentClaimId: string
  projectId: string
}

export function useEventLogs({ contractId, abiFragmentClaimId, projectId }: Options) {
  const [state, dispatch] = useReducer(eventLogsReducer, initialState)
  const actions = useMemo(
    // @ts-expect-error action creator
    () => bindActionCreators(actionCreators, dispatch) as EventLogActions,
    [dispatch],
  )

  const { data: evmContract } = trpc.sources.evmContractV1.show.useQuery(
    {
      id: contractId,
      projectId,
    },
    { enabled: contractId && projectId ? true : false },
  )

  const { data: abiFragmentCoverage } = trpc.sources.evmContractV1.abiFragmentIndexingClaims.show.useQuery(
    {
      projectId,
      id: abiFragmentClaimId,
    },
    {
      enabled: abiFragmentClaimId && projectId ? true : false,
    },
  )

  React.useEffect(() => {
    if (abiFragmentCoverage) {
      actions.setAbiFragment({ abiFragment: abiFragmentCoverage })
    }
  }, [abiFragmentCoverage])

  const trpcUtils = trpc.useUtils()

  trpc.sources.evmContractV1.eventLogs.watch.useSubscription(
    {
      abiFragmentIndexingClaimId: abiFragmentClaimId,
      projectId,
    },
    {
      onData(data: any) {
        dispatch(data)
      },
    },
  )

  React.useEffect(() => {
    if (evmContract) {
      actions.setContract({ contract: evmContract })
    }
  }, [evmContract])

  const utils = trpc.useUtils()
  const handleRefreshItems = React.useCallback(() => {
    actions.setIsLoading({ isLoading: true })

    utils.sources.evmContractV1.eventLogs.list
      .fetch({ projectId, contractId, abiFragmentId: abiFragmentClaimId })
      .then((result) => {
        actions.refreshItems({ items: result.items })
      })
  }, [utils])

  // const loadMoreBelow = React.useCallback(() => {
  //   if (!state.isLoadingTail) {
  //     actions.setIsLoadingAtTail(true)
  //     trpcUtils.sources.evmContractV1.eventLogs.itemsBefore
  //       .fetch({
  //         contractID,
  //         eventID,
  //         before: state.tail,
  //       })
  //       .then((result) => {
  //         return actions.addItems({ items: result.items })
  //       })
  //       .then((res) => {
  //         actions.setIsLoadingAtTail(false)
  //       })
  //       .catch((err) => {
  //         console.error(err)
  //         actions.setIsLoadingAtTail(false)
  //       })
  //   }
  // }, [state.isLoadingTail, state.tail, actions.setIsLoadingAtTail])

  // const loadMoreAbove = React.useCallback(() => {
  //   trpcUtils.sources.evmContractV1.eventLogs.list
  //     .fetch({
  //       contractID,
  //       eventID,
  //     })
  //     .then((result) => {
  //       actions.addItems({ items: result.items })
  //     })
  // }, [state.head])

  /**
   * On first mount
   */
  React.useEffect(() => {
    actions.setIsLoading({ isLoading: true })
    trpcUtils.sources.evmContractV1.eventLogs.list
      .fetch({
        projectId: projectId,
        contractId: contractId,
        abiFragmentId: abiFragmentClaimId,
        limit: 100,
      })
      .then((result) => {
        actions.refreshItems({ items: result.items })
        // actions.addItems({ items: result.items })
      })
  }, [])

  return {
    state,
    dispatch,
    /**
     * These actions should be bound to dispatch
     */
    actions: {
      ...actions,
      refreshItems: handleRefreshItems,
    },
  }
}
