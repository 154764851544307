import clsx from 'clsx'
import React, { forwardRef } from 'react'
import { Button, type ButtonProps } from '../primitives/button'
import { Spinner } from '../primitives/spinner'

interface LoadingButtonProps extends ButtonProps {
  isLoading?: boolean
  loadingText?: string
}

export const LoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>(
  (
    {
      isLoading = false,
      className,
      leftIcon,
      type = 'submit',
      children = undefined,
      loadingText = undefined,
      ...props
    },
    ref,
  ) => {
    const body = React.useMemo(() => {
      if (!isLoading) return children
      if (loadingText) return loadingText
      return children
    }, [children, isLoading, loadingText])

    return (
      <Button
        ref={ref}
        leftIcon={!isLoading && leftIcon}
        className={clsx('relative flex justify-center ', className)}
        disabled={isLoading}
        {...props}
      >
        {isLoading && <Spinner className="absolute" />}
        <div className={clsx({ invisible: isLoading })}>{body}</div>
      </Button>
    )
  },
)
