import { Page } from '@/components/page'
import { namedURLs } from '@/lib/named-routes'
import { type SourceConfig, allSourceTypes } from 'common'
import { Link } from 'react-router-dom'
import { CardGrid, ItemCard, type RFC, SourceLogo } from 'ui'

const SourceCard: RFC<{ option: SourceConfig }> = ({ option }) => {
  const itemCardContent = (
    <ItemCard
      title={option.name}
      description={option.description}
      id={option.id}
      disabled={!option.available}
      icon={<SourceLogo variant="base" sourceType={option.id} />}
    />
  )

  return (
    <>
      {option.available ? (
        <Link draggable={false} to={namedURLs.sources.new({ sourceTypeId: option.id })}>
          {itemCardContent}
        </Link>
      ) : (
        itemCardContent
      )}
    </>
  )
}

const SourcesGrid: RFC<{ items: Array<SourceConfig> }> = ({ items = [] }) => {
  return (
    <CardGrid>
      {items.map((option) => (
        <SourceCard option={option} key={option.id} />
      ))}
    </CardGrid>
  )
}

function NewSourcePage() {
  const available = Object.values(allSourceTypes).filter((item) => item.available)
  const comingSoon = Object.values(allSourceTypes).filter((item) => !item.available)

  return (
    <Page title="Add New Source" className="space-y-8">
      <div className="space-y-4">
        <h3>Available</h3>
        <SourcesGrid items={available} />
      </div>
      <hr />
      <div className="space-y-4">
        <h3>Coming Soon</h3>
        <SourcesGrid items={comingSoon} />
      </div>
    </Page>
  )
}

export default NewSourcePage
