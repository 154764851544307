import { Page } from '@/components'
import { namedURLs, trpc } from '@/lib'
import type { InferLoaderData } from '@/trpc-client'
import { Tab } from '@headlessui/react'
import type { PageWithLoader } from 'common-web'
import { getProjectIdFromCookie } from 'common/src'
import { MoreVerticalIcon } from 'lucide-react'
import React, { useMemo } from 'react'
import { type Params, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  AddressLabel,
  Button,
  Debug,
  DescriptionTable,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  EditableLabel,
  NetworkLabel,
  TabNav,
  betterToast,
} from 'ui'
import { AbiFragmentCoveragesTable } from './components/abi-fragment-coverages-table'
import { AddRelatedContractTab } from './components/add-related-contract-tab'
import { useEvmContractDetail } from './components/hooks'
import { RelatedContractsTab } from './components/related-contracts-tab'

const tabs = [
  { label: 'Contract Events', id: 'events' },
  { label: 'Contract ABI', id: 'contract-abi' },
  { label: 'Related Contracts', id: 'related' },
  { label: 'Add Related Contract', id: 'add-related' },
  // { label: 'Destinations', id: 'destinations' },
] as const

export const loader = async ({ params }: { params: Params<'id'> }) => {
  const projectId = getProjectIdFromCookie(document.cookie)
  return {
    projectId,
    id: params.id,
  }
}

const ShowEVMContractPage: PageWithLoader = ({ className = undefined, children = undefined, ...props }) => {
  const params = useParams()
  const { projectId, id } = useLoaderData() as InferLoaderData<typeof loader>
  const { data: evmContract, refetch: refetchEvmContract } = trpc.sources.evmContractV1.show.useQuery(
    { projectId, id: id as string },
    { enabled: !!id && !!projectId },
  )

  const navigate = useNavigate()
  const deleteMutation = trpc.sources.evmContractV1.delete.useMutation()

  const handleDelete = React.useCallback(() => {
    deleteMutation.mutateAsync({ projectId, id: params.id as string }).then(() => {
      betterToast.success('Contract Deleted')
      navigate(namedURLs.sources.list())
    })
  }, [deleteMutation, navigate, projectId])

  const location = useLocation()

  const { abiFragmentCoverages, isLoading } = useEvmContractDetail({
    contractId: params.id,
    projectId,
  })

  const contractInfoSummary = React.useMemo(() => {
    if (evmContract) {
      return {
        Network: <NetworkLabel value={evmContract?.network} />,
        Address: <AddressLabel value={evmContract?.address} />,
        'Internal Prefix': <div className="font-mono">{evmContract?.prefix}</div>,
      }
    }
    return {}
  }, [evmContract])

  const onUpdate = trpc.sources.evmContractV1.update.useMutation()

  const currentTab = new URLSearchParams(location.search).get('tab') || tabs[0].id
  const selectedIndex = useMemo(() => tabs.findIndex((tab) => tab.id === currentTab), [currentTab])

  const handleTabChange = (tabId: string) => {
    navigate({
      pathname: location.pathname,
      search: `?tab=${tabId}`,
    })
  }

  const handleNameChange = React.useCallback(
    (label: string) => {
      return onUpdate.mutateAsync({ id: id as string, projectId, label }).then(() => {
        betterToast.success('Contract name updated')
        refetchEvmContract()
      })
    },
    [params.id, projectId, onUpdate],
  )

  return (
    <Page
      titleText={evmContract?.label || evmContract?.address}
      title={
        evmContract?.label && (
          <EditableLabel className="w-full text-xl" defaultValue={evmContract.label} onChange={handleNameChange} />
        )
      }
      action={
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="p-2">
              <MoreVerticalIcon className="w-4 h-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={handleDelete}>Delete</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      }
      {...props}
    >
      <DescriptionTable items={contractInfoSummary} />
      <Tab.Group selectedIndex={selectedIndex} onChange={(index) => handleTabChange(tabs[index].id)}>
        <div className="-mx-lg">
          <Tab.List as={TabNav.Menu} className="px-lg">
            {tabs.map((tab, index) => (
              <Tab
                as={TabNav.Tab}
                selected={selectedIndex === index}
                key={tab.id}
                className="focus:outline-none text-sm"
              >
                {tab.label}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel id="events" className={'px-lg py-md'}>
              <AbiFragmentCoveragesTable isLoading={isLoading} items={abiFragmentCoverages} params={params} />
            </Tab.Panel>
            <Tab.Panel id="contract-abi" className={'px-lg py-md'}>
              <Debug value={evmContract?.originalContractAbi} />
            </Tab.Panel>
            <Tab.Panel id="abi" className={'px-xl py-lg'}>
              {id && <RelatedContractsTab contractId={id} />}
            </Tab.Panel>
            {/* <Tab.Panel id="implementations" className={'px-xl py-lg'}></Tab.Panel> */}
            <Tab.Panel id="related" className={'px-xl py-lg'}>
              {params.id && evmContract?.network && (
                <AddRelatedContractTab contractId={params.id} network={evmContract?.network} />
              )}
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </Page>
  )
}
ShowEVMContractPage.loader = loader
export default ShowEVMContractPage
