export const Networks = {
  'arbitrum-mainnet': 'arbitrum-mainnet',
  'arbitrum-sepolia': 'arbitrum-sepolia',
  'avalanche-mainnet': 'avalanche-mainnet',
  'base-goerli': 'base-goerli',
  'base-mainnet': 'base-mainnet',
  'base-sepolia': 'base-sepolia',
  'blast-mainnet': 'blast-mainnet',
  'blast-sepolia': 'blast-sepolia',
  'bsc-mainnet': 'bsc-mainnet',
  'celo-mainnet': 'celo-mainnet',
  'cyber-mainnet': 'cyber-mainnet',
  'cyber-sepolia': 'cyber-sepolia',
  'ethereum-goerli': 'ethereum-goerli',
  'ethereum-mainnet': 'ethereum-mainnet',
  'ethereum-rinkeby': 'ethereum-rinkeby',
  'ethereum-sepolia': 'ethereum-sepolia',
  'fantom-mainnet': 'fantom-mainnet',
  'fauxchain-testnet': 'fauxchain-testnet',
  'harmony-mainnet': 'harmony-mainnet',
  'optimism-mainnet': 'optimism-mainnet',
  'polygon-mainnet': 'polygon-mainnet',
  'scroll-mainnet': 'scroll-mainnet',
  'scroll-sepolia': 'scroll-sepolia',
  'solana-mainnet': 'solana-mainnet',
  'zora-mainnet': 'zora-mainnet',
} as const

export type NetworkID = keyof typeof Networks

export const envifyNetwork = (n: NetworkID): string => n.replace('-', '_').toUpperCase()

export const AllNetworks: Array<NetworkID> = Object.keys(Networks) as Array<NetworkID>

export const isValidNetworkID = (s: string | NetworkID): s is NetworkID => {
  return Object.keys(Networks).includes(s)
}

export class InvalidNetworkID extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'InvalidNetworkID'
  }
}

export const parseNetworkID = (s: string | NetworkID): NetworkID => {
  if (!isValidNetworkID(s)) {
    throw new InvalidNetworkID(`${s} is an invalid network ID`)
  }
  return s as NetworkID
}
