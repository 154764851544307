import { Page, useProjectId } from '@/components'
import { trpc } from '@/lib/trpc'
import React from 'react'
import { useParams } from 'react-router-dom'
import { AddressLabel, Debug, DescriptionTable, NetworkLabel, type RFC, clsx } from 'ui'
import { EventLogTable } from './event-log-table'
import { NewEventsCard } from './new-events-card'
import { useEventLogs } from './redux'

const ShowSighashPage: RFC = ({ className = undefined, children = undefined, ...props }) => {
  const projectId = useProjectId()
  const { id, fragmentId } = useParams()
  if (!id || !fragmentId) {
    throw new Error('No id or fragmentId')
  }

  const { state, actions } = useEventLogs({
    contractId: id,
    abiFragmentClaimId: fragmentId,
    projectId,
  })

  const { data: eventLog } = trpc.sources.evmContractV1.showAbiFragment.useQuery(
    {
      id: fragmentId,
      projectId,
    },
    {
      enabled: id && fragmentId && projectId ? true : false,
      refetchOnMount: true,
    },
  )

  const vitals = React.useMemo(() => {
    return {
      Network: eventLog?.network ? <NetworkLabel value={eventLog?.network} /> : '',
      Name: eventLog?.fullNameWithParams,
      'Contract Address': eventLog?.address ? <AddressLabel value={eventLog?.address} /> : '',
      Sighash: <div className="font-mono">{eventLog?.sighash}</div>,
    }
  }, [eventLog])

  return (
    <Page
      title={<span className="font-mono tracking-tight font-bold">{eventLog?.fullNameWithParams}</span>}
      titleText={eventLog?.fullName}
      className={clsx(className, 'h-full')}
      fullWidth
      {...props}
    >
      <NewEventsCard
        countNewEvents={state.newEventsSeen}
        currentTip={state.head}
        isLoading={state.isLoadingHead}
        onClick={() => actions.refreshItems()}
        className={clsx('transition-all', {})}
      />
      <DescriptionTable items={vitals} />
      {eventLog?.inputs && (
        <EventLogTable isLoadingHead={state.isLoadingHead} items={state.items} params={eventLog.inputs as Array<any>} />
      )}
    </Page>
  )
}

export default ShowSighashPage
