import { Page } from '@/components'
import { useProject } from '@/contexts'
import { trpc } from '@/lib'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { BetterForm, LoadingButton, betterToast } from 'ui'

const NewApiKeyPage = () => {
  const navigate = useNavigate()
  const { id: projectId } = useProject()
  const createApiKey = trpc.dashboard.apiKeys.create.useMutation()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const onSubmit = async ({ name }: { name: string }) => {
    try {
      setIsSubmitting(true)
      if (!projectId) {
        betterToast.error('Please select a project before performing this operation')
        return
      }
      const data = await createApiKey.mutateAsync({
        projectId,
        name,
      })
      if (data) {
        navigate('..')
        betterToast.success('API Key Created')
      }
    } catch (_error) {
      setIsSubmitting(false)
    }
  }

  const form = useForm({
    defaultValues: {},
  })

  return (
    <Page title="New API Key">
      <BetterForm.Provider form={form} onSubmit={onSubmit}>
        <BetterForm.Field autoFocus={true} label="API Key Name" name="name" />
        <LoadingButton isLoading={isSubmitting}>Create API Key</LoadingButton>
      </BetterForm.Provider>
    </Page>
  )
}

export default NewApiKeyPage
