import { Page } from '@/components'
import { trpc } from '@/lib'
import type { InferLoaderData } from '@/trpc-client'
import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import type { PageWithLoader } from 'common-web'
import { getProjectIdFromCookie } from 'common/src'
import { Plus } from 'lucide-react'
import React, { useCallback, useEffect, useReducer } from 'react'
import { type Params, useLoaderData, useNavigate } from 'react-router-dom'
import { LoadingButton, TabNav } from 'ui'
import { actionCreators, initialState, reducer } from './redux'
import { Step1Card, type Step1FormSchema } from './step1-card'
import { type SettingsFormType, Step2TableView } from './step2-card'
import { CodeEditor } from './step2-editor'

async function loader({ params, request }: { params: Params<'id'>; request }) {
  const projectId = getProjectIdFromCookie(document.cookie)
  const url = new URL(request.url)
  const sourceId = url.searchParams.get('sourceId')
  const destinationId = url.searchParams.get('destinationId')
  return {
    projectId,
    id: params.id,
    sourceId,
    destinationId,
  }
}

const NewLinkPage: PageWithLoader = ({ className = undefined, ...props }) => {
  const navigate = useNavigate()
  const { id, projectId, sourceId, destinationId } = useLoaderData() as InferLoaderData<typeof loader>

  const updateSearchParams = (obj: Record<string, string | number>) => {
    const searchParams: Record<string, any> = new URLSearchParams(location.search)
    Object.entries(obj).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString())
      }
    })
    if (searchParams.sourceId || searchParams.destinationId) {
      // updateSearchParams({ sourceId: searchParams.sourceId, destinationId: searchParams.destinationId })
    }
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true })
  }

  const { data: allSources } = trpc.sources.list.useQuery({ projectId })
  const { data: allDestinations } = trpc.destinations.list.useQuery({ projectId })
  const [state, dispatch] = useReducer(reducer, initialState, (arg: any) => {
    return {
      ...arg,
      destinationId,
      sourceId,
    }
  })

  const trpcUtils = trpc.useUtils()

  const onStep1Submit = React.useCallback(
    (values: Step1FormSchema) => {
      updateSearchParams({ sourceId: values.sourceId, destinationId: values.destinationId })

      trpcUtils.linksRouter.listContractAbiFragmentIndexingClaims
        .fetch({
          projectId,
          id: values.sourceId,
        })
        .then((result) => {
          dispatch(actionCreators.setContractAbiFragmentIndexingClaims(result))
        })
    },
    [projectId, trpcUtils],
  )

  const onSettingsChange = React.useCallback(
    (values: SettingsFormType) => {
      trpcUtils.linksRouter.listContractAbiFragmentIndexingClaims
        .fetch({
          projectId,
          id: sourceId!,
          viewPrefix: values.viewPrefix,
        })
        .then((result) => {
          dispatch(actionCreators.setContractAbiFragmentIndexingClaims(result))
        })
    },
    [state.sourceId, trpcUtils],
  )

  const tabs = [
    { label: 'Table', id: 'table' },
    { label: 'Code', id: 'code' },
  ] as const

  const currentTab = new URLSearchParams(location.search).get('tab') || tabs[0].id
  const selectedIndex = React.useMemo(() => tabs.findIndex((tab) => tab.id === currentTab), [currentTab])
  const handleTabChange = (tabId: string) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('tab', tabId)
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }

  const handleSubmit = useCallback(() => {
    dispatch(actionCreators.setIsSubmitting(true))

    if (!state.code) {
      throw new Error('cannot submit empty ')
    }

    trpcUtils.client.linksRouter.create
      .mutate({
        projectId,
        destinationId: state.destinationId!,
        code: state.code,
      })
      .then((result) => {
        navigate('/links')
      })
  }, [dispatch, state.code, state.destinationId])

  /**
   * on page load, if both ids are already there (from the url bar), then simulate a submit
   */
  useEffect(() => {
    if (sourceId && destinationId) {
      onStep1Submit({ sourceId, destinationId })
    }
  }, [])

  return (
    <Page title="New Link" className={clsx(className, '')} {...props}>
      <div className="-mx-lg">
        <Step1Card
          className="mx-lg max-w-full mb-xl"
          defaultValues={{ sourceId, destinationId }}
          allSources={allSources || []}
          allDestinations={allDestinations || []}
          onSubmit={onStep1Submit}
        />
        <hr />
        <div className="flex justify-between px-lg mt-xl w-full">
          <div>
            <h3>Step 2. Specify Table Mappings</h3>
            <p>Sorry this form sucks, I promise we will make it better.</p>
          </div>
          <div> </div>
        </div>
        <Tab.Group selectedIndex={selectedIndex} onChange={(index) => handleTabChange(tabs[index].id)}>
          <Tab.List as={TabNav.Menu} className="w-full px-lg mt-md flex">
            {tabs.map((tab, index) => (
              <Tab
                as={TabNav.Tab}
                key={tab.id}
                selected={selectedIndex === index}
                className="focus:outline-none text-sm"
              >
                {tab.label}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel id="step2" className="py-md mx-lg">
              <Step2TableView
                className="mt-lg"
                onSubmit={handleSubmit}
                onSettingsChange={onSettingsChange}
                contractAbiFragmentIndexingClaims={state.contractAbiFragmentIndexingClaims || []}
                viewPrefix={state.viewPrefix}
                // TODO: sourceId bug is from here
                sourceId={state.sourceId!}
              />
            </Tab.Panel>
            <Tab.Panel id="step2-code" className="px-lg py-md">
              <CodeEditor value={state.code} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
        <hr />
        <div className="my-lg mx-lg flex justify-end">
          <LoadingButton
            onClick={handleSubmit}
            isLoading={state.isSubmitting}
            loadingText="Creating Views"
            type="submit"
            variant={'blue'}
            leftIcon={<Plus className="w-4 h-4" />}
          >
            Create Views
          </LoadingButton>
        </div>
      </div>
    </Page>
  )
}

NewLinkPage.loader = loader

export default NewLinkPage
