import { type RFC, clsx } from 'ui'

export const FormGroup: RFC<{ label: string; description?: string }> = ({
  className = undefined,
  children = undefined,
  label,
  description = undefined,
  ...props
}) => {
  return (
    <div className={clsx('space-y-2', className)} {...props}>
      <div className="text-slate-700 font-medium">{label}</div>
      <div className="form-control">{children}</div>
      <div>{description}</div>
    </div>
  )
}
