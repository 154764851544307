import clsx from 'clsx'
import type { RFC } from 'common-web'
import type React from 'react'
import { CheckCircle2, Debug, Spinner } from 'ui'

type Props = { ratio: number; status: 'indexing' | 'done' }

export const SyncStatusBadge: RFC<Props> = ({ status, ratio, className = undefined, ...props }) => {
  let icon: React.ReactElement | undefined = undefined
  let label = 'Ready'

  if (status === 'indexing') {
    icon = <Spinner />
    label = 'Indexing'
  } else if (status === 'done') {
    label = 'Done'
    icon = <CheckCircle2 className="h-4 w-4 " />
  }

  return (
    <div
      className={clsx('flex items-center whitespace-nowrap text-slate-500 line-clamp-1', className)}
      suppressHydrationWarning
      {...props}
    >
      {icon && <span className="mr-1">{icon}</span>}
      {label}
      {status === 'indexing' && Number.isFinite(ratio) && ` (${(ratio * 100).toFixed(2)}%)`}
    </div>
  )
}
