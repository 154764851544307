'use client'
import React from 'react'

import type * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import clsx from 'clsx'

import { forwardRef } from 'react'
import { Checkbox } from '../primitives'

type Props = React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
  label: any
  id: string
  labelClassName?: any
}

const CheckboxWithLabel = forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, Props>(
  ({ className, label, id, labelClassName, ...props }, ref) => (
    <div className="items-center flex space-x-2">
      <Checkbox id={id} className={className} ref={ref} {...props} />
      <div className="grid gap-1.5 leading-none">
        <label
          htmlFor={id}
          className={clsx('leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70', labelClassName)}
        >
          {label}
        </label>
      </div>
    </div>
  ),
)

export { CheckboxWithLabel }
