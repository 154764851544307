import { EventWithSighashCard, SyncStatusBadge } from '@/components'
import { createColumnHelper } from '@tanstack/react-table'
import type { ContractAbiFragmentIndexingProgressType } from 'common/src/chainpipe/redux'
import NumberFormat from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import { BetterTable, Progress } from 'ui'

const columnHelper = createColumnHelper<ContractAbiFragmentIndexingProgressType>()

const sz = (s: number) => ({ size: s, minSize: s, maxSize: s })

const columns = [
  columnHelper.display({
    header: 'Name',
    ...sz(300),
    cell: (info) => {
      const item = info.row.original
      return (
        <EventWithSighashCard
          name={
            item.fullName ||
            // @ts-ignore
            item.name
          }
          sighash={item.sighash}
        />
      )
    },
  }),
  columnHelper.display({
    id: 'events-seen',
    ...sz(100),
    header: () => <div className="text-right whitespace-nowrap">Events Seen</div>,
    cell: (info) => {
      const item = info.row.original
      return (
        <div className="text-right">
          <NumberFormat className="text-right" thousandSeparator value={item.totalCount || 0} displayType="text" />
        </div>
      )
    },
  }),
  columnHelper.display({
    id: 'sync-status',
    ...sz(200),
    header: () => <div className="text-right whitespace-nowrap">Sync Status</div>,
    cell: (info) => {
      const obj = info.row.original
      return (
        <div className="space-y-2 w-full">
          <Progress value={obj.progressRatio * 100} className="col-span-3" />
          <SyncStatusBadge ratio={obj.progressRatio} status={obj?.active ? 'indexing' : 'done'} />
        </div>
      )
    },
  }),
]

export const AbiFragmentCoveragesTable = ({
  items,
  params,
  isLoading = false,
}: {
  items: Array<ContractAbiFragmentIndexingProgressType>
  params: Record<string, string | undefined>
  isLoading?: boolean
}) => {
  const navigate = useNavigate()
  const { id: abiFragmentId } = params
  if (!abiFragmentId) {
    throw new Error('Expected abiFragmentId')
  }

  return (
    <BetterTable
      style={{ tableLayout: 'fixed' }}
      hoverable
      isLoading={isLoading}
      onNavigate={navigate}
      data={items}
      columns={columns}
    />
  )
}
