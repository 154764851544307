import { createColumnHelper } from '@tanstack/react-table'
import clsx from 'clsx'
import type { RFC } from 'common-web'
import React from 'react'
import { useForm } from 'react-hook-form'
import { BetterForm, BetterTable, Button, FormField, Input, zod, zodResolver } from 'ui/src'
import type { ContractAbiFragmentIndexingClaimsWithViewName } from './redux'

const settingsFormSchema = zod.object({
  viewPrefix: zod.string().nullable(),
})

export type SettingsFormType = zod.infer<typeof settingsFormSchema>

export const Step2SettingsForm: RFC<{
  defaultValues: { viewPrefix: string | undefined }
  onSubmit: (values: SettingsFormType) => void | Promise<void>
}> = ({ defaultValues, onSubmit }) => {
  const form = useForm({
    defaultValues,
    resolver: zodResolver(settingsFormSchema),
  })

  return (
    <BetterForm.Provider form={form} onSubmit={onSubmit}>
      <div className="flex space-x-md items-center">
        <FormField control={form.control} name={'viewPrefix'} render={({ field }) => <Input {...field} />} />
        <Button type="submit">Update Table Prefix</Button>
      </div>
    </BetterForm.Provider>
  )
}

const columnHelper = createColumnHelper<ContractAbiFragmentIndexingClaimsWithViewName[number]>()
const columns = [
  columnHelper.display({
    id: 'name',
    header: 'Name',
    cell: (info) => (
      <div className="font-mono">{info.row.original.fullName || info.row.original.name}</div>
      // <EventWithSighashCard
      //   name={info.row.original.fullName || info.row.original.name || 'unnamed'}
      //   sighash={info.row.original.sighash}
      // />
    ),
  }),
  columnHelper.accessor('viewName', {
    header: 'View Name',
    cell: (info) => <Input type="text" className="font-mono w-full min-w-[400px]" value={info.row.original.viewName} />,
  }),
]

export const Step2TableView: RFC<{
  viewPrefix: string | undefined
  contractAbiFragmentIndexingClaims: ContractAbiFragmentIndexingClaimsWithViewName
  onSettingsChange: (values: SettingsFormType) => void | Promise<void>
  onSubmit: (values: any) => void | Promise<void>
  sourceId: string
}> = ({
  className = undefined,
  contractAbiFragmentIndexingClaims = [],
  onSettingsChange,
  onSubmit,
  viewPrefix = undefined,
  sourceId,
  ...props
}) => {
  const handleSubmit = React.useCallback(
    (values) => {
      return onSettingsChange({
        ...values,
        sourceId,
      })
    },
    [sourceId],
  )
  return (
    <div className={clsx(className)} {...props}>
      <div className="flex justify-between">
        <Step2SettingsForm onSubmit={handleSubmit} defaultValues={{ viewPrefix: viewPrefix }} />
      </div>

      <BetterTable
        className={clsx('gap-x-sm gap-y-lg', className)}
        {...props}
        data={contractAbiFragmentIndexingClaims}
        columns={columns}
      />
    </div>
  )
}
