import { ButtonLink, Page } from '@/components'
import { namedURLs } from '@/lib/named-routes'
import { type InferLoaderData, proxyClient } from '@/trpc-client'
import { Tab } from '@headlessui/react'
import { createColumnHelper } from '@tanstack/react-table'
import type { PageWithLoader } from 'common-web'
import { getProjectIdFromCookie } from 'common/src'
import { Plus } from 'lucide-react'
import React from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { BetterTable, Button, Column, Debug, DestinationLogoWithLabel, Row, TabNav } from 'ui'

const loader = async () => {
  const projectId = getProjectIdFromCookie(document.cookie)
  const destinations = await proxyClient.destinations.list.query({ projectId })
  return {
    destinations,
    projectId,
  }
}

const columnHelper = createColumnHelper<any & { createdAt: string }>()

const columns = [
  columnHelper.accessor('label', {
    cell: (info) => (
      <Column className="space-y-sm">
        <DestinationLogoWithLabel
          className="font-medium"
          variant="sm"
          destinationType={info.row.original.destinationType}
        />
      </Column>
    ),
    header: 'Name',
  }),

  columnHelper.accessor('createdAt', {
    header: 'Created At',
    cell: (info) => <div>{info?.getValue()?.toDateString()}</div>,
  }),
]

const ListDestinationsPage: PageWithLoader = () => {
  const { destinations } = useLoaderData() as InferLoaderData<typeof loader>
  const navigate = useNavigate()

  const handleClickCreate = React.useCallback(() => {
    navigate(namedURLs.destinations.add())
  }, [navigate])

  return (
    <Page
      title="Destinations"
      action={
        <Button onClick={handleClickCreate} leftIcon={<Plus className="w-4 h-4" />}>
          Add Destination
        </Button>
      }
    >
      <BetterTable data={destinations} columns={columns} hoverable onNavigate={navigate}>
        <Column center className="space-y-md">
          <div>You have no destinations yet. Set one up?</div>
          <ButtonLink
            className="shadow-md"
            variant="green"
            to="/destinations/new"
            leftIcon={<Plus className="w-4 h-4" />}
          >
            Create Destination
          </ButtonLink>
        </Column>
      </BetterTable>
    </Page>
  )
}

ListDestinationsPage.loader = loader
export default ListDestinationsPage
